<template>
  <div class="blog-page blog-page-categories-index">
    <div class="blog-page--hero">
      <h1 class="blog-page--page-title">
        {{ $t('All Categories') }}
      </h1>
    </div>
    <div v-if="categories && categories.length > 0">
      <div class="blog-page--category-entries">
        <div class="container">
          <div class="row row--entries">
            <div class="col col-md-6 col-lg-4" v-for="(category) in categories" :key="category.id">
              <div class="blog-page--preview-small">
                <div class="blog-page--preview-small-inner">
                  <h3 class="blog-page--preview-title">
                    <router-link :to="localizedRoute(`/categories/${category.slug}`)">
                      {{ category.name }}
                    </router-link>
                  </h3>
                  <router-link :to="localizedRoute(`/categories/${category.slug}`)" class="blog-page--preview-link">
                    {{ $t('View') }} {{ category.name }}&nbsp;<span class="slide-right">&rtrif;</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('There are no categories.') }}</h3>
    </div>
  </div>
</template>

<script>
import Categories from 'src/modules/vsf-wordpress/components/categories/Index.js'
export default {
  mixins: [Categories],
  name: 'CategoriesIndex'
}
</script>

<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.blog-page-categories-index {
  background: #f9f9f9;
  .blog-page--hero {
    background: #000;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: 30px * 1.5;
  }
  .blog-page--page-title {
    display: block;
    text-align: center;
    color: $white;
    margin: 0 auto;
    max-width: 650px;
  }
  .blog-page--category-entries {
    .blog-page--preview-small {
      margin: 0;
    }
    .blog-page--preview-title + .blog-page--subcategory-list {
      margin-top: -15px;
    }
  }
  .blog-page--subcategory-list {
    padding: 20px;
    background: #f3f3f3;
    margin: 30px 0 0;
    h5 {
      margin: 0 0 20px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 5px 0;
        a {
          color: lighten( $nearly-black, 30 );
          margin: 0;
        }
      }
    }
  }
  .blog-page--preview-link {
    &--and-more {
      color: #a2a2a2;
      margin: 0;
      font-size: 13px;
    }
  }
  .blog-page-subcategories-count {
    margin: -12px 0 6px;
    font-family: $PTSans;
    font-size: 12px;
    letter-spacing: 1.25px;
  }
}
</style>
