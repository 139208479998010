import { mapGetters } from 'vuex'

export default {
  serverPrefetch () {
    return this.fetchCategories()
  },
  computed: {
    ...mapGetters({
      categories: 'wordpress/categories',
      currentCategoryPageInfo: 'wordpress/currentCategoryPageInfo',
      currentCategoryEdges: 'wordpress/currentCategoryEdges'
    })
  },
  mounted () {
    this.fetchCategories()
  },
  methods: {
    fetchCategories () {
      return this.$store.dispatch('wordpress/loadCategories', { first: 25 })
    }
  }
}
